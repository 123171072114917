<script>
import STable from '@/components/Table'
import * as echarts from 'echarts'
import { loadWebsiteList } from '@/api'

export default {
  name: 'Domain',
  components: {
    STable
  },
  data () {
    return {
      selectedRowKeys: [],
      columns: [
        {
          title: this.$t('website.domain'),
          dataIndex: 'website_host',
          key: 'domain',
          width: 220,
          align: 'center',
          fixed: 'left',
          ellipsis: true
        },
        {
          title: this.$t('website.port'),
          dataIndex: 'website_port',
          align: 'center',
          width: 80
        },
        {
          title: this.$t('website.ip'),
          dataIndex: 'ip',
          align: 'center',
          width: 140
        },
        {
          title: this.$t('website.status'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
          width: 140
        },
        {
          title: this.$t('cert.validity.period'),
          dataIndex: 'cert',
          scopedSlots: { customRender: 'cert' },
          align: 'center',
          width: 200
        },
        {
          title: this.$t('user.website.add.time'),
          dataIndex: 'create_time',
          align: 'center',
          width: 180
        },
        {
          title: this.$t('website.remark'),
          dataIndex: 'remark',
          align: 'center'
        },

        {
          title: this.$t('user.action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
          width: 200
        }
      ],
      queryParams: {},
      loadData: (parameter) => {
        return loadWebsiteList(Object.assign(this.queryParams, parameter)).then(res => {
          return res.result
        })
      }
    }
  },
  created () {
    this.$nextTick(() => {
      var accessChart = echarts.init(this.$refs.accessChart)
      var certChart = echarts.init(this.$refs.certChart)
      loadWebsiteList({ pageSize: 500, pageNo: 1 }).then(res => {
         const data = res.result.data
        const accessStatusData = [{ value: 0, name: this.$t('user.website.unknown') }, { value: 0, name: this.$t('user.website.normal') }, { value: 0, name: this.$t('user.website.certExpiration') }, { value: 0, name: this.$t('user.website.unreachable') }, { value: 0, name: this.$t('user.website.cert.invalid') }]
         const certStatusData = [{ value: 0, name: this.$t('cert.status.normal') }, { value: 0, name: this.$t('cert.status.expiring.soon') }, { value: 0, name: this.$t('cert.status.expired') }, { value: 0, name: this.$t('cert.status.invalid') }]
        for (const item of data) {
          accessStatusData[item.status].value++
          if (item.cert_end_time) {
            if (item.cert_use_days >= item.cert_total_days) {
              certStatusData[2].value++
            } else if (item.cert_total_days - item.cert_use_days < 15) {
              certStatusData[1].value++
            } else {
              certStatusData[0].value++
            }
          } else {
            certStatusData[3].value++
          }
        }
        accessChart.setOption({
          tooltip: {},
          series: [
            {
              name: this.$t('website.access.status'),
              type: 'pie',
              data: accessStatusData
            }
          ]
        })
        certChart.setOption({
          tooltip: {},
          series: [
            {
              name: this.$t('cert.status'),
              type: 'pie',
              data: certStatusData
            }
          ]
        })
      })
    })
  },
  methods: {
    onSelectChange (selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<template>
  <div >
    <div>
      <a-row :gutter="12">
        <a-col :span="12">
          <a-card :title="$t('website.access.status.stat')">
            <div style="height: 300px;" ref="accessChart"></div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card :title="$t('website.cert.status.stat')">
            <div style="height: 300px;" ref="certChart"></div>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div class="ant-card" style="padding: 4px;margin-top:12px; ">
      <div>
        <a-button icon="delete" type="danger" :disabled="selectedRowKeys.length==0" >{{ $t('website.action.delete') }}</a-button>
        <!--        <a-button class="margin-left-16" icon="to-top">{{ $t('website.monitoring.speed.upgrade') }}</a-button>-->
        <a-button icon="plus" type="primary" class="margin-left-16">{{ $t('website.add') }}</a-button>
      </div>
      <div class="margin-top-16">
        <s-table
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :rowKey="(record)=>record._id"
          :columns="columns"
          :data="loadData"
          bordered
          :scroll="{x:1380}"
          size="small"
          :locale="tableLocale">
          <div slot="status" slot-scope="text,record">
            <a-tag v-if="record.status==1" color="green">{{ $t('user.website.normal') }}</a-tag>
            <a-tag v-if="record.status==0">{{ $t('user.website.unknown') }}</a-tag>
            <a-tag v-if="record.status==2" color="orange">{{ $t('user.website.certExpiration') }}</a-tag>
            <a-tag v-if="record.status==3" color="red">{{ $t('user.website.unreachable') }}</a-tag>
            <a-tag v-if="record.status==4" color="red">{{ $t('user.website.cert.invalid') }}</a-tag>
          </div>
          <div slot="cert" slot-scope="text,record">
            <div v-if="record.show_progress" style="padding: 0 16px;">
              <a-progress
                :percent="record.cert_use_days*100/record.cert_total_days"
                :status="(record.cert_total_days-record.cert_use_days>15?'success':'exception')">
                <template #format="percent">
                  <span>{{ record.cert_use_days }}/{{ record.cert_total_days }}</span>
                </template>
              </a-progress>
            </div>
            <div v-else>-</div>
          </div>
          <div slot="action" slot-scope="text,record">
            <a style="color: red;">{{ $t('user.action.delete') }}</a>
            <a-divider type="vertical"></a-divider>
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                {{ $t('user.action.more') }} <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;">{{ $t('user.cert.recheck') }}</a>
                </a-menu-item>

                <a-menu-item>
                  <a :href="'https://'+record.website_host+':'+record.website_port" target="_blank">{{ $t('user.website.jump') }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </s-table>
      </div>

    </div>
  </div>
</template>

<style scoped>

</style>
